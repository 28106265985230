thead tr {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 2 !important;
  background: #5b6268;
  /* border-bottom: 1px solid #000 !important; */
  color: #fff;
}

.fixed-last {
  box-shadow: inset -1px 0px 0px rgba(0, 0, 0, 0.5);
}

.fixed-position {
  position: sticky !important;
  left: 0;
}

.table-striped2 thead tr {
  background-color: #5b6268;
}

.table-striped2 thead tr th {
  padding: 11px 5px !important;
  vertical-align: middle;
}

.table-striped2 tbody tr {
  background-color: #fff;
}

.fixed-last .table-box {
  border-right: 1px solid #333;
}

.table-box {
  border: 1px solid #dbdbdb;
  height: 41px;
  margin-top: -1px;
  border-bottom: none;
  padding: 5px;
  border-left: none;
  margin-left: -3px;
}

.table-excel td {
  padding: 0;
}

.table-striped2 tbody tr:nth-of-type(odd) {
  background-color: #f2f2f2;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.blink {
  animation: blinker 1.5s linear infinite;
}

h1 {
  font-weight: lighter !important;
  font-size: 22px !important;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

td {
  font-size: 13px;
}

.faturamentoProd {
  overflow-y: scroll;
  border: 1px solid #d9d9d9;
  float: left;
  width: 100%;
  height: 40vh;
  position: relative;
  margin-top: 5px;
  margin-bottom: 5px;
}

.faturamentoProd::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

.separatioList {
  overflow: scroll;
  height: 50vh;
}

.modal-header {
  background: #f1f1f1;
}

.lotes {
  position: absolute;
  width: 97%;
  height: 90%;
}

@media print {
  p {
    font-size: 20px;
    color: red;
  }

  td {
    font-size: 20px;
    color: red;
  }
}