.btnEmpresa, .btnProduct {
  border-bottom: 1px solid #f1f1f1;
  width: 100%;
  padding: 10px 10px 10px 20px;
  cursor: pointer;
}

.btnEmpresa:hover, .btnProduct:hover {
  background-color: #f1f1f1;
}

.produto-linha, .lote-linha {
  cursor: pointer;
}

.active-conferencia {
  background-color: #007bff !important;
  color: #fff !important;
  cursor: default !important;
}

.lote-linha-box {
  background-color: #fff;
  position: absolute;
  width: calc(100% - 47px);
  z-index: 999;
}

.lote-linha.active-conferencia {
  background-color: #007bff !important;
}

#no-products {
  margin-top: 28vh;
}

.boxLot {
  display: none;
}

.active-lot-box {
  display: block;
  position: absolute;
  width: 99%;
  top: 0;
  z-index: 9;
}

#company-table-body, #lots-table-body {
  cursor: pointer;
}

#modal-lot {
  display: none;
}

.selected-lot-row {
  display: block;
}

.table-spinner, .table-spinner2 {
  display: none;
}

.table-spinner.loading-active, .table-spinner2.loading-active {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 9;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}

#page-container {
  position: relative;
  min-height: 100vh;
}

#content-wrap {
  padding-bottom: 11rem;    /* Footer height */
}

#footer {
  position: absolute;
  left: 10px;
  bottom: 0;
  width: 100%;
  height: 10rem;            /* Footer height */
}

#page-container {
  position: relative;
  min-height: 100vh;
}

#content-wrap {
  padding-bottom: 11rem;    /* Footer height */
}

#footer {
  position: absolute;
  left: 10px;
  bottom: 0;
  width: 100%;
  height: 10rem;            /* Footer height */
}