aside {
  min-height: 100vh !important;
}


#labelTemporario {
  margin-top: 30px;
  margin-left: 30px;
  font-size: 20px;
}

#temporario {
  margin-top: 40px;
  margin-left: 0px;
  font-size: 20px;
  transform: scale(2);
  padding: 10px;
}

.total_embalagem_pedida {
  color: #00ff7e;
}

#modalTable th {
  vertical-align: top;
}
.highlight {
  background-color: #afe3ff;
}
