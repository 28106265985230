.btnEmpresa,
.companyButton {
  border-bottom: 1px solid #f1f1f1;
  width: 100%;
  padding: 10px 10px 10px 20px;
  cursor: pointer;
  height: 45px;
  background-color: #fff;
}

.btnEmpresa:hover {
  background-color: #f1f1f1;
}

.produto-linha,
.lote-linha {
  cursor: pointer;
}

.active-conferencia {
  background-color: #007bff !important;
  color: #fff !important;
  cursor: default !important;
}

.lote-linha-box {
  background-color: #fff;
  position: absolute;
  width: calc(100% - 47px);
  z-index: 999;
}
.lote-linha.active-conferencia {
  background-color: #007bff !important;
}

#no-products {
  margin-top: 28vh;
}

.btnLiberarFaturamento {
  float: right;
  margin-top: -4px;
  display: none;
}

.active-conferencia .btnLiberarFaturamento {
  display: block;
}

.PDFHeader p {
  font-size: 1rem;
}

table {
  font-size: 0.825rem;
}
